import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import Payment from '../../pages/Payment';
import Footer from '../Footer';
import PrivacyPolicy from '../../pages/PrivacyPolicy';
import NotFound from '../../pages/NotFound';
import LegalNotice from '../../pages/LegalNotice';
import './style.scss';
import { getEnvVar, getIdFromUrl, isResume } from '../../utils';
import { SessionData, SessionStyleData } from './types';

const PageLayout = () => {
  const { i18n } = useTranslation();
  const [isSessionLoaded, setSessionLoaded] = useState<boolean>(false);
  const [displayLogo, setDisplayLogo] = useState<boolean>(false);

  const [sessionData, setSessionData] = useState<SessionData>(() => {
    const storedData = sessionStorage.getItem('ptStyleData');
    return storedData
      ? (JSON.parse(storedData) as SessionData)
      : {
          name: 'Paymenttools',
          paypage: {
            logoUrl: 'images/pt-logo.svg',
            theme: { primaryColor: '#2E3859', primaryFontColor: '#FFFFFF' }
          }
        };
  });

  const setLanguage = (lang: string) => {
    i18n
      .changeLanguage(lang)
      .then(() => {
        sessionStorage.setItem('ptPreferredLanguage', lang);
      })
      .catch(() => {});
  };

  useEffect(() => {
    const SESSION_HEADER_NAME = 'X-PT-SESSION';
    const sessionHeader = sessionStorage.getItem(SESSION_HEADER_NAME);
    const ECOM_API_URL = getEnvVar('REACT_APP_ECOM_API') as string;
    const sessionId = getIdFromUrl();
    if (sessionId) {
      fetch(`${ECOM_API_URL}/sessions/${sessionId}`, {
        method: 'GET',
        credentials: 'include',
        headers: { [SESSION_HEADER_NAME]: sessionHeader || '' }
      })
        .then((res) => {
          if (res.status === 401) {
            fetch(`${ECOM_API_URL}/sessions/current`, {
              method: 'POST',
              credentials: 'include',
              headers: { [SESSION_HEADER_NAME]: sessionHeader || '' }
            })
              .then((response) => response.json())
              .then((data: SessionStyleData) => {
                if (data.preferredLanguage) {
                  setLanguage(data.preferredLanguage);
                }
                if (data.styleData) {
                  setSessionData(data.styleData);
                  sessionStorage.setItem('ptStyleData', JSON.stringify(data.styleData));
                  if (data.styleData.dropin) {
                    setDisplayLogo(!!data.styleData.dropin.logoUrl);
                  }
                  setSessionLoaded(true);
                } else if (isResume()) {
                  setSessionLoaded(true);
                }
              })
              .catch(() => {
                // Handle the error.
              });
          } else if (res.status === 200) {
            if (res.headers && res.headers.get(SESSION_HEADER_NAME)) {
              sessionStorage.setItem(
                SESSION_HEADER_NAME,
                res.headers.get(SESSION_HEADER_NAME) as string
              );
            }
          }
          return res.json();
        })
        .then((data: SessionStyleData) => {
          if (data.preferredLanguage) {
            setLanguage(data.preferredLanguage);
          }
          if (data.styleData) {
            setSessionData(data.styleData);
            sessionStorage.setItem('ptStyleData', JSON.stringify(data.styleData));
            if (data.styleData.dropin) {
              setDisplayLogo(!!data.styleData.dropin.logoUrl);
            }
            setSessionLoaded(true);
          } else if (isResume()) {
            setSessionLoaded(true);
          }
        })
        .catch(() => {
          // Handle the error.
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <div className="pp-page">
        <div className="pp-content">
          <Routes>
            <Route
              path="/"
              element={<Payment displayLogo={displayLogo} isSessionLoaded={isSessionLoaded} />}
            />
            <Route path="legal-notice" element={<LegalNotice />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer theme={sessionData.paypage.theme} />
      </div>
    </BrowserRouter>
  );
};

export default PageLayout;
